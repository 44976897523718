import "./App.scss";
import HeroSection from "./pages/HeroSection/HeroSection";

function App() {
  return (
    <div>
      <HeroSection />
    </div>
  );
}

export default App;
